export function ParseDate(SourceData: Array<object>, TargetArray: Array<object>, DateFields: string[]) {

    $.each(SourceData, function (i, row) {

        let unixPattern = /Date\(([^)]+)\)/,
            cSharpPattern = /\S*(T)\S*/,
            index = DateFields.length;

        function parse() {
            let unixResults = unixPattern.exec(row[DateFields[index]]),
                cSharpResults = cSharpPattern.exec(row[DateFields[index]]);

            if (unixResults) {
                let fullDate = new Date(parseFloat(unixResults[1]));
                row[DateFields[index]] = (fullDate.getMonth() + 1)
                    + "/" + fullDate.getDate()
                    + "/" + fullDate.getFullYear();
            }

            if (cSharpResults) {
                let dateString = row[DateFields[index]].split('T')[0];
                row[DateFields[index]] = dateString.split('-')[1]
                    + '/' + dateString.split('-')[2]
                    + '/' + dateString.split('-')[0]
            }
        }

        if (index === 1) {
            index = 0;
            parse();
        }

        while (index--)
            parse()

        TargetArray.splice(i, 1, row);
    });
}