interface Set<T> {
    add(value: T): Set<T>;
    clear(): void;
    delete(value: T): boolean;
    entries(): Array<[T, T]>;
    forEach(callbackfn: (value: T, index: T, set: Set<T>) => void, thisArg?: any): void;
    has(value: T): boolean;
    keys(): Array<T>;
    readonly size: number;
}

interface SetConstructor {
    new <T>(): Set<T>;
    new <T>(iterable: Array<T>): Set<T>;
    readonly prototype: Set<any>;
}
declare var Set: SetConstructor;

export function FindIntersect(a, b, id): any[] {
    let set = new Set(a.map(id));
    return b.filter(el => set.has(id(el)));
}