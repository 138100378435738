export function UrlSegments(index?: number) {
    let urlSegments: string[] = window.location.pathname.split('/');

    // Remove the empty string before the first '/'
    if (urlSegments.shift() === "")
        urlSegments.slice(1, -1);
    // Remove the empty string after the last '/'
    if (urlSegments.slice(-1)[0] === "")
        urlSegments.splice(-1, 1);
    if (!index) {
        if (index === 0) {
            return urlSegments[0];
        }
        else return urlSegments;        
    }
    else {
        return urlSegments[index];
    }
    
}